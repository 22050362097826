import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
    primary: {
        light: '#89BCE7', // Azul Claro Tarjetas Cuenca
        main: '#3D4274', // Azul Oscuro Digital
        dark: '#89BCE7', // Azul Claro Tarjetas Cuenca
        gray: '#B2B6C8', // Gris
        buttonHover: '#9BA0B7',
    },
};

const breakpoints = {
    values: {
        mobile: 700,
        tablet: 768,
        desktop: 1400,
    },
};

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true;
        tablet: true;
        desktop: true;
    }
}

export default createMuiTheme({ palette, breakpoints });
